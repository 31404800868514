export default function () {
  document.addEventListener("DOMContentLoaded", function () {
    var dropdownItems = document.querySelectorAll(".p-globalNav__item.-dropdown");

    dropdownItems.forEach(function (item) {
      item.addEventListener("mouseover", function () {
        var submenu = this.querySelector(".p-globalNav__submenu");
        submenu.classList.add("is-active");
      });

      item.addEventListener("mouseout", function () {
        var submenu = this.querySelector(".p-globalNav__submenu");
        submenu.classList.remove("is-active");
      });
    });
  });
}
