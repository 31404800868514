export default function () {
  // DOM取得
  const tabMenus = document.querySelectorAll(".js-tab__menu-item");

  // イベント付加
  tabMenus.forEach((tabMenu) => {
    tabMenu.addEventListener("click", tabSwitch);
  });

  // イベントの処理
  function tabSwitch(e) {
    // クリックされた要素のデータ属性を取得
    const tabTargetData = e.currentTarget.dataset.tab;
    // クリックされた要素の親要素と、その子要素を取得
    const tabList = e.currentTarget.closest(".js-tab__menu");
    const tabItems = tabList.querySelectorAll(".js-tab__menu-item");

    // クリックされた要素の親要素の兄弟要素の子要素を取得
    const tabPanelItems = tabList.nextElementSibling.querySelectorAll(".js-tab__panel-box");

    // クリックされたtabの同階層のmenuとpanelのクラスを削除
    tabItems.forEach((tabItem) => {
      tabItem.classList.remove("is-active");
    });
    tabPanelItems.forEach((tabPanelItem) => {
      tabPanelItem.classList.remove("is-show");
    });

    // クリックされたmenu要素にis-activeクラスを付加
    e.currentTarget.classList.add("is-active");
    // クリックしたmenuのデータ属性と等しい値を持つパネルにis-showクラスを付加
    tabPanelItems.forEach((tabPanelItem) => {
      if (tabPanelItem.dataset.panel === tabTargetData) {
        tabPanelItem.classList.add("is-show");
      }
    });

    // ページ内リンクの既定の挙動をキャンセル
    e.preventDefault();

    // タブまでスクロールする
    const tabOffset = tabList.offsetTop;
    const scrollToOffset = tabOffset - 100; // タブの位置から100px上にスクロールする

    // smoothscrollのオプションを一時的に無効化
    const originalBehavior = document.documentElement.style.scrollBehavior;
    document.documentElement.style.scrollBehavior = "auto";

    window.scrollTo({
      top: scrollToOffset,
    });

    // smoothscrollのオプションを元に戻す
    document.documentElement.style.scrollBehavior = originalBehavior;
  }

  // 他のページからの挙動

  document.addEventListener("DOMContentLoaded", function () {
    // URLのハッシュをチェックし、該当のタブを開く
    const hash = window.location.hash.substr(1);
    if (hash) {
      const tab = document.querySelector(`li[data-tab="${hash}"]`);
      if (tab) {
        const tabList = tab.closest(".js-tab__menu");
        const tabContents = tabList.nextElementSibling.querySelectorAll(".js-tab__panel-box");
        tabContents.forEach(function (content) {
          content.classList.remove("is-show");
        });
        const targetContent = document.querySelector(`.js-tab__panel-box[data-panel="${hash}"]`);
        if (targetContent) {
          targetContent.classList.add("is-show");
        }

        // タブまでスクロールする
        const tabOffset = tabList.offsetTop;
        const scrollToOffset = tabOffset - 50;
        // smoothscrollのオプションを一時的に無効化
        const originalBehavior = document.documentElement.style.scrollBehavior;
        document.documentElement.style.scrollBehavior = "auto";

        setTimeout(() => {
          window.scrollTo(0, scrollToOffset);
          // smoothscrollのオプションを元に戻す
          document.documentElement.style.scrollBehavior = originalBehavior;
        }, 0);
      }
    }
  });

  // ナビゲーションのリンクを取得
  const navigationLinks = document.querySelectorAll(".js-nav-link");

  // ナビゲーションのリンクにクリックイベントを付加
  navigationLinks.forEach((link) => {
    link.addEventListener("click", handleNavigationClick);
  });

  // ナビゲーションのリンクをクリックしたときの処理
  function handleNavigationClick(e) {
    // クリックしたリンクのデータ属性を取得
    const tabTargetData = e.currentTarget.dataset.tab;

    // クリックしたリンクの親要素と、その子要素を取得
    const tabList = e.currentTarget.closest(".js-tab__menu");
    const tabItems = tabList.querySelectorAll(".js-tab__menu-item");

    // クリックしたリンクの親要素の兄弟要素の子要素を取得
    const tabPanelItems = tabList.nextElementSibling.querySelectorAll(".js-tab__panel-box");

    // クリックしたリンクの同階層のmenuとpanelのクラスを削除
    tabItems.forEach((tabItem) => {
      tabItem.classList.remove("is-active");
    });
    tabPanelItems.forEach((tabPanelItem) => {
      tabPanelItem.classList.remove("is-show");
    });

    // クリックしたリンクのmenu要素にis-activeクラスを付加
    e.currentTarget.classList.add("is-active");

    // クリックしたリンクのデータ属性と等しい値を持つパネルにis-showクラスを付加
    tabPanelItems.forEach((tabPanelItem) => {
      if (tabPanelItem.dataset.panel === tabTargetData) {
        tabPanelItem.classList.add("is-show");
      }
    });

    // タブまでスクロールする
    const tabOffset = tabList.offsetTop;
    const scrollToOffset = tabOffset - 100; // タブの位置から100px上にスクロールする

    // smoothscrollのオプションを一時的に無効化
    const originalBehavior = document.documentElement.style.scrollBehavior;
    document.documentElement.style.scrollBehavior = "auto";

    window.scrollTo({
      top: scrollToOffset,
    });

    // smoothscrollのオプションを元に戻す
    document.documentElement.style.scrollBehavior = originalBehavior;

    // デフォルトのリンクの挙動をキャンセル
    e.preventDefault();
  }
}
