export const heroSlider = {
  loop: true, // ループさせる
  effect: "fade", // フェードのエフェクト
  autoplay: {
    delay: 4000, // 4秒後に次の画像へ
    disableOnInteraction: false, // ユーザー操作後に自動再生を再開する
  },
  speed: 2000, // 4秒かけながら次の画像へ移動
  allowTouchMove: false, // マウスでのスワイプを禁止
};

export const primarySlider = {
  slidesPerView: 1,
  loop: true,
  speed: 300,
  spaceBetween: 24,
  // centeredSlides: true, // アクティブなスライドを中央に配置する
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },

  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 32,
    },
  },
};
