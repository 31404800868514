// Polyfill
import "intersection-observer";
import objectFitImages from "object-fit-images";
import closetPolyfill from "./lib/closet.polyfill";
objectFitImages();
closetPolyfill();

// Libraly
import Loading from "./lib/Loading";
// import $ from "jquery";
import Swiper from "swiper/bundle";
import { heroSlider, primarySlider } from "./lib/swiper-init";
import SmoothScroll from "smooth-scroll";
// import ScrollObserver from './lib/ScrollObserver';
import Toggle from "./lib/Toggle";
// import pageTop from "./lib/pageTop";
import tabMenu from "./lib/tabMenu";
import accordion from "./lib/accordion";
// import gallery from "./lib/gallery";
// import modal from "./lib/modal";
// import Close from "./lib/Close";
// import Slider from "./lib/Slider";
import dropdown from "./lib/dropdown";
// import { inview } from './lib/inview';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

//初期化

//ローディング
new Loading();

let options = {
  // header: ".p-header",
  offset: 100,
};
new SmoothScroll('a[href*="#"]', options);

new Toggle(".js-drawer");
new dropdown();
// new pageTop();
new tabMenu();
new accordion();
// new modal();
// new gallery();

//ヘッダーの固定
document.addEventListener("DOMContentLoaded", function () {
  var headerElement = document.querySelector(".p-header"); // .p-header要素を取得

  window.addEventListener("scroll", function () {
    if (window.pageYOffset > 800) {
      // 画面上部から800px以上スクロールされた場合、.fixedクラスを追加します
      headerElement.classList.add("-fixed");
    } else {
      // 画面上部から800px未満にスクロールされた場合、.fixedクラスを削除します
      headerElement.classList.remove("-fixed");
    }
  });
});

//ドロワーページ内リンク
document.querySelectorAll(".p-globalNav__link").forEach(function (element) {
  element.addEventListener("click", function () {
    document.documentElement.classList.remove("is-drawerActive");
  });
});

window.addEventListener("resize", function () {
  var windowSize = window.innerWidth;
  if (windowSize < 1260) {
    document.querySelector(".p-globalNav").style.transition = "transform .2s ease-in-out";
  }
});

/*===================*/
/*  swiper設定
/*===================*/
new Swiper(".heroSlider", heroSlider);
new Swiper(".primarySlider", primarySlider);

/*===================*/
/*  GSAP設定
/*===================*/
gsap.registerPlugin(ScrollTrigger);

const elements = document.querySelectorAll(".fadeIn");

elements.forEach((element) => {
  gsap.from(element, {
    opacity: 0,
    duration: 1,
    y: 20,
    scrollTrigger: {
      trigger: element,
      start: "top 80%", // 要素の上端がウィンドウの80%の位置に達したらアニメーション開始
      end: "top 20%", // 要素の上端がウィンドウの20%の位置に達したらアニメーション終了
      toggleActions: "play none none reset", // アニメーションを最初から再生して終了時にリセット
    },
  });
});
